import contentIndexesQuery from '@gql/queries/contentIndexesQuery';
import { toValue } from 'vue';

export default async function (payload) {
    const { currentCenter } = useCenters();
    const { locale, localeFromRoute } = useLocale();

    const center = toValue(currentCenter).slug;

    const docs = ref(null);
    const nextPage = ref(null);
    const prevPage = ref(null);
    const totalDocs = ref(null);
    const totalPages = ref(null);

    const getVariables = (id = '1', limit = 1, center, collection, filters, page = 1, locale, textSearch) => {
        return {
            id,
            limit,
            center,
            collection,
            filters,
            page,
            locale,
            textSearch,
        };
    };

    const variables = getVariables(
        toValue(payload.id),
        toValue(payload.limit),
        center,
        toValue(payload.collection),
        toValue(payload.filters),
        toValue(payload.page),
        toValue(localeFromRoute) || toValue(locale),
        toValue(payload.textSearch),
    );

    // SSR Query
    const response = await useAsyncQuery(contentIndexesQuery, variables);
    writeLastQuery(contentIndexesQuery, variables, 'contentIndexesQuery');

    const data = response?.data?.value?.contentIndexes;

    docs.value = data?.docs;
    nextPage.value = data?.nextPage;
    prevPage.value = data?.prevPage;
    totalDocs.value = data?.totalDocs;
    totalPages.value = data?.totalPages;

    //Client Query
    watch([payload], async () => {
        const variables = getVariables(
            toValue(payload.id),
            toValue(payload.limit),
            center,
            toValue(payload.collection),
            toValue(payload.filters),
            toValue(payload.page),
            toValue(localeFromRoute) || toValue(locale),
            toValue(payload.textSearch),
        );

        await writeLastQuery(contentIndexesQuery, variables, 'contentIndexesQuery');
        const { onResult } = useQuery(contentIndexesQuery, variables, 'contentIndexesQuery');
        onResult((queryResult) => {
            const data = queryResult.data.contentIndexes;
            docs.value = data?.docs;
            nextPage.value = data?.nextPage;
            prevPage.value = data?.prevPage;
            totalDocs.value = data?.totalDocs;
            totalPages.value = data?.totalPages;
        });
    });

    return { docs, nextPage, prevPage, totalDocs, totalPages };
}
