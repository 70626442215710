import { gql } from 'graphql-tag';
import SummaryFragment from '@fragments/summaries/SummaryFragment';

export default gql`
    query contentIndexes(
        $collection: String!
        $id: String!
        $center: String!
        $page: Int
        $limit: Int
        $filters: Filters
        $locale: LocaleInputType
        $textSearch: String
    ) {
        contentIndexes(
            collection: $collection
            id: $id
            center: $center
            page: $page
            limit: $limit
            filters: $filters
            locale: $locale
            textSearch: $textSearch
        ) {
            totalDocs
            totalPages
            nextPage
            prevPage
            docs {
                ...SummaryFragment
            }
        }
    }

    ${SummaryFragment}
`;
